<template>
  <div>
    <!-- pc -->
    <div class="header-pc">
      <div class="header-container">
        <div class="flex header" style="height:100%;width:1152px;margin:auto">
          <div
            class="unselect size-24 weight-700 position-relative flex-align"
            style="margin-right: 16px;"
            @click="clickLogo"
          >
            <img src="/static/img/header_logo.svg" style="vertical-align: middle" alt="런치팩" />
          </div>
          <!-- menu -->
          <div class="flex-between" style="flex:1">
            <div class="flex" style="width:100%;height:100%;flex:1">
              <div
                v-for="(menu, idx) in menusPc"
                :key="'menu-' + idx"
                class="table-cell subtitle6 selected-menu unselect"
                @mouseover="selectedMenu = menu"
              >
                <div class="menu-name" @click="clickMenu(menu)">
                  {{ menu.name }}
                  <template v-if="menu.child">
                    <img class="arrow-up" src="/static/icon/ic_arrow-up.svg" />
                    <img class="arrow-down" src="/static/icon/ic_arrow-down.svg" />
                  </template>
                </div>

                <!-- 2depth menu -->
                <div class="selected-menu-wrapper" v-if="selectedMenu && selectedMenu.child">
                  <template v-for="(item, idx) in selectedMenu.child">
                    <template v-if="item.child">
                      <div class="menu-item-1 flex-align" :key="`item-${idx}`">
                        <div class="menu-item-name-1 body4-bold">{{ item.name }}</div>
                        <div
                          v-for="(child_item, child_idx) in item.child"
                          :key="`child_item-${child_idx}`"
                          class="menu-item-2 body4"
                          :style="selectedChildStyle(child_item)"
                          @click="clickMenu(child_item)"
                        >
                          {{ child_item.name }}
                        </div>
                      </div>
                    </template>
                    <div
                      v-else
                      :key="`item-${idx}`"
                      class="menu-item-2 body4 flex-align"
                      style="gap:10px"
                      @click="clickMenu(item)"
                    >
                      <img v-if="item.icon" :src="`/static/icon/${item.icon}.svg`" />
                      <div class="flex-align" style="gap: 4px;">
                        <div>{{ item.name }}</div>
                        <img
                          style="width: 16px; height: 16px;"
                          v-if="item.icon_right"
                          :src="`/static/icon/${item.icon_right}.svg`"
                          clss="svg-sub3"
                        />
                      </div>
                      <div v-if="item.trial" class="tag-trial">무료체험</div>
                    </div>
                  </template>
                </div>
              </div>
            </div>

            <!-- right menu -->
            <div class="flex-end" style="gap: 24px;">
              <div v-if="isLogin">
                <notifications ref="notifications" />
              </div>
              <!-- <div @click="$router.push('/inquiry_reg?type_id=20')" class="table-cell unselect">
                <div class="menu-name" style="padding:16px">제휴문의</div>
              </div> -->
              <!-- <div class="divider-vertical"></div> -->
              <div v-if="isLogin" class="user-region unselect" @mouseover="hideNotifications">
                <img class="account_outline" src="/static/icon/avata_default.png" alt="" />
                <span>{{ userName }}</span>
                <img class="arrow_down" src="/static/icon/u_angle-down.svg" />

                <div class="lp-mypage-menu-bg">
                  <div class="lp-mypage-menu">
                    <div
                      v-for="(menu, idx) in myPageMenu"
                      :key="`my-page-menu-${idx}`"
                      class="unselect mypage-item"
                      style="padding:12px"
                      @click="clickMyMenu(menu)"
                    >
                      {{ menu.name }}
                    </div>
                  </div>
                </div>
              </div>
              <template v-else>
                <div @click="clickLogin" class="table-cell unselect" style="height:100%">
                  <div class="menu-name" style="padding:16px;padding-left:0">로그인</div>
                </div>
                <div @click="clickSignup" class="table-cell unselect" style="height:100%">
                  <div class="menu-name" style="padding:16px;padding-left:0">회원가입</div>
                </div>
              </template>
              <!-- <a href="https://pfa.platformfy.com/theme_market" target="_blank"> -->
              <a v-if="!isPartner" href="https://walla.my/survey/gZBAG9imMdaiyop6z6It" target="_blank">
                <button class="button is-primary body4-medium" style="width:140px;height:36px">무료로 시작하기</button>
              </a>
              <div v-else class="partner_center_wrapper">
                <div class="partner_center_btn unselect" style="height:36px">
                  파트너 센터
                  <!-- 파트너 센터 호버 시 메뉴 노출 -->
                  <div class="partner_center_menu-wrapper">
                    <div class="partner_center_menu">
                      <div
                        v-for="(menu, idx) in partnerCenterMenu"
                        :key="`partner-menu-${idx}`"
                        class="partner_center_menu_item unselect"
                        @click="clickPartnerMenu(menu)"
                      >
                        {{ menu.name }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- mobile -->
    <div class="header-mobile" v-if="visibleHeaderMobile">
      <div class="header-container">
        <div class="flex-between" style="height: 100%;padding:0 4px">
          <div class="unselect size-22 weight-700 padding-12 position-relative" @click="clickLogo">
            <img src="/static/img/header_logo.svg" style="vertical-align: middle" alt="런차팩" />
          </div>
          <div class="flex-align" style="gap: 16px;">
            <notifications ref="notifications" v-if="isLogin" />
            <img class="unselect padding-12" src="/static/icon/fi_menu.svg" @click="$refs.pageDrawerLeft.open()" />
          </div>
        </div>
      </div>
    </div>

    <!-- 드로워 (모바일) -->
    <drawer class="header-mobile" direction="right" :exist="true" ref="pageDrawerLeft" @close="onCloseDrawerLeft">
      <div class="drawer-container">
        <!-- header -->
        <div class="flex-end drawer-header">
          <img class="padding-12" src="/static/icon/fi_close.svg" @click="$refs.pageDrawerLeft.close()" />
        </div>
        <template v-if="!isLogin">
          <div class="login-wrapper">
            <button class="button is-sub body4-medium" style="width:100%; height:44px" @click="clickLogin">
              로그인
            </button>
          </div>
        </template>
        <template v-else>
          <div class="btn-trial-container">
            <div class="flex-align">
              <div v-if="isLogin" class="user-region unselect">
                <img class="account_outline" src="/static/icon/avata_default.png" alt="" />
                <span class="subtitle5 sub2">{{ userName }}</span>
                <!-- <span class="margin-left-12 margin-right-12">|</span>
                <div class="body4 sub2 margin-right-16" @click="clickLogout">로그아웃</div> -->
              </div>
              <div @click="$router.push('signin')" class="table-cell unselect" v-else>
                <div class="body4 sub2 margin-right-16" @click="clickLogin">로그인</div>
              </div>
            </div>
            <div class="btn-trial-wrapper">
              <!--<a href="https://pfa.platformfy.com/theme_market" target="_blank">-->
              <a v-if="!isPartner" href="https://walla.my/survey/gZBAG9imMdaiyop6z6It" target="_blank">
                <button
                  class="button is-primary subtitle5 margin-right-12"
                  style="height:44px;width:100%"
                  @click="closeDrawerLeft"
                >
                  무료로 시작하기
                </button>
              </a>
              <template v-else>
                <button
                  class="partner_center_btn-mobile subtitle5"
                  style="height:44px;width:100%"
                  @click="moveToPartnerCenter('/partner_center/my_template')"
                >
                  파트너 센터
                </button>
                <button
                  class="button is-sub subtitle5"
                  style="height:44px;width:100%"
                  @click="moveToPartnerCenter('/partner_center/developer_center')"
                >
                  파트너 개발자 센터
                </button>
              </template>
            </div>
          </div>
        </template>
        <!-- menu mobile -->
        <div class="drawer-content">
          <div
            v-for="(menu, idx) in menusMobile"
            :key="'menu-' + idx"
            class="table-cell selected-menu unselect"
            @click="clickFirstMenu(menu)"
          >
            <div class="flex-between padding-16" style="border-bottom: 1px solid #eeeeee">
              <div class="subtitle5">{{ menu.name }}</div>
              <img
                :src="`/static/icon/fi_chevron-${selectedMenu && menu.path === selectedMenu.path ? 'up' : 'down'}.svg`"
                class="svg-sub4"
                v-if="menu.child"
              />
            </div>

            <div class="selected-menu-wrapper" v-if="selectedMenu && menu.path === selectedMenu.path">
              <template v-for="(item, idx) in selectedMenu.child">
                <template v-if="item.child">
                  <div class="menu-item-1" :key="`item-${idx}`">
                    <div class="subtitle7">{{ item.name }}</div>
                    <div
                      class="menu-item-2"
                      v-for="(child_item, child_idx) in item.child"
                      :key="`child_item-${child_idx}`"
                      @click="clickMenu(child_item)"
                    >
                      <div class="body2 sub3" :class="{ 'margin-top-12': !(item.name === '' && child_idx === 0) }">
                        {{ child_item.name }}
                      </div>
                    </div>

                    <div
                      class="lp-divider-gray1 margin-top-24 margin-bottom-24"
                      v-if="idx < selectedMenu.child.length - 1"
                    ></div>
                  </div>
                </template>
                <div
                  v-else
                  class="menu-item-2 flex-align"
                  style="gap:12px"
                  :key="`item-${idx}`"
                  @click="clickMenu(item)"
                >
                  <div class="body4 sub3" :class="{ 'margin-top-16': idx !== 0 }">{{ item.name }}</div>
                  <div v-if="item.trial" class="tag-trial">무료체험</div>
                </div>
              </template>
            </div>
          </div>
          <!-- <div class="table-cell selected-menu flex-align padding-16" style="gap:8px" @click="clickInquiry">
            <img src="/static/icon/u_comment.svg" style="width:18px" />
            <div class="body3 sub2">제휴문의</div>
          </div> -->
        </div>
      </div>
    </drawer>

    <!-- 로딩 -->
    <div class="lp-loading" v-if="loadingStatus">
      <div class="flex" style="justify-content: center;">
        <i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
      </div>
    </div>
    <transition name="fade">
      <keep-alive :include="alivePages">
        <router-view style="min-height:calc(100vh - 64px)"></router-view>
      </keep-alive>
    </transition>

    <!-- 포탈 -->
    <!-- tooltip 컴포넌트 포탈 -->
    <portal-target name="tooltip" :multiple="true"></portal-target>

    <portal-target name="time-picker" :multiple="true"></portal-target>

    <portal-target name="confirm-modal" :multiple="true"></portal-target>

    <portal-target name="btn-modal" :multiple="true"></portal-target>

    <!-- 푸터 -->
    <lp-footer v-if="visibleFooter"></lp-footer>

    <lp-sign-modal ref="signModal"></lp-sign-modal>

    <!-- 주문제작 신청 -->
    <sweet-modal ref="reqModal">
      <lp-service-inquiry-modal @closeModal="$refs.reqModal.close()"></lp-service-inquiry-modal>
    </sweet-modal>

    <!-- Top 버튼 -->
    <transition name="fade">
      <div @click="clickTop" class="btn-top unselect" v-show="vTop">
        <i class="material-icons" style="font-size:30px">arrow_drop_up</i><br />TOP
      </div>
    </transition>
  </div>
</template>

<script>
import LpFooter from './component/LpFooter';
import LpSignModal from './modal/LpSignModal';
import { SweetModal } from 'sweet-modal-vue';
import LpServiceInquiryModal from './modal/LpServiceInquiryModal';
import Drawer from './component/Drawer';
import Notifications from './component/Notifications';
export default {
  name: 'Main',
  components: {
    Drawer,
    LpServiceInquiryModal,
    SweetModal,
    LpSignModal,
    LpFooter,
    Notifications,
  },

  created() {
    this.setAuthHeader();
    this.$store.commit('setLoading', false);
    this.$root.$on('login', () => {
      this.$refs.signModal.open();
    });
    window.addEventListener('scroll', () => {
      let top = document.getElementsByTagName('html')[0].scrollTop;
      this.vTop = top > 300 && this.visibleTop;
    });
  },

  watch: {
    '$store.getters.openLogin'(n) {
      if (n) {
        this.$refs.signModal.open();
        // this.$router.replace();
        this.$store.commit('setOpenLogin', false);
      }
    },
  },

  computed: {
    // TOP 버튼 노출 조건
    visibleTop() {
      let exclude = ['/service_inquiry_detail', '/design_inquiry_detail', '/service_create'];

      return exclude.indexOf(this.$route.path) === -1;
    },
    //모바일 헤더 노출 조건
    visibleHeaderMobile() {
      let exclude = [
        '/service_inquiry_detail',
        '/design_inquiry_detail',
        '/trial_account',
        '/template_inquiry_detail',
        '/template_manufacture_detail',
      ];

      return exclude.indexOf(this.$route.path) === -1;
    },
    //푸터 노출 조건
    visibleFooter() {
      let exclude = [
        '/inquiry_reg',
        'inquiry_reg',
        '/service_inquiry_detail',
        '/design_inquiry_detail',
        '/service_create',
        '/trial_account',
        '/template_manufacture_detail',
      ];

      return exclude.indexOf(this.$route.path) === -1;
    },
    menusPc() {
      let menus = this.menus;
      if (this.isPartner) {
        menus = menus.filter((i) => i.name !== '파트너 센터');
      }
      return menus;
    },
    menusMobile() {
      let menus = this.menus.filter((i) => i.name !== '앱 마켓');
      if (this.isPartner) {
        menus = menus.filter((i) => i.name !== '파트너 센터');
      }
      if (this.isLogin) {
        let menu = [
          {
            name: '마이페이지',
            path: 'mypage',
            child: [
              {
                name: '',
                child: [
                  { name: '내 서비스', path: '/mypage/my_service' },
                  { name: '문의내역', path: '/mypage/inquiry' },
                  { name: '미팅 관리', path: '/mypage/my_meeting' },
                  { name: '계약내역', path: '/mypage/my_contract' },
                  { name: '소스 파일 다운로드', path: '/mypage/my_templates' },
                  { name: '북마크', path: '/mypage/my_bookmark' },
                  { name: '내 정보 수정', path: '/mypage/profile' },
                ],
              },
            ],
          },
          {
            name: '로그아웃',
            path: 'logout',
          },
        ];
        return menus.concat(menu);
      } else {
        return menus;
      }
    },
    loadingStatus() {
      return this.$store.getters.loading;
    },
    userName() {
      if (this.$store.getters.user.user_id > 0) return this.$store.getters.user.name;
      else return '';
    },
  },
  data() {
    return {
      alivePages: ['Home', 'Store', 'Market'],
      vTop: false,
      selectedMenu: undefined,
      menus: [
        {
          name: '솔루션 소개',
          path: '',
          child: [
            {
              name: '플랫폼 빌더 솔루션',
              //path: 'https://pfa.platformfy.com',
              path: 'https://platformfy.com/platformfy_info',
              icon_right: 'fi_external-link',
              trial: true,
            },
            {
              name: '플러그인 마켓',
              path: 'https://platformfy.com/market/list',
              icon_right: 'fi_external-link',
            },
            {
              name: '플랫폼 관리 솔루션',
              path: '/solution_info',
            },
            {
              name: '플랫폼 관리 솔루션 API',
              path: '/info?id=3',
            },
          ],
        },
        {
          name: '플랫폼 템플릿',
          path: '/template_home',
        },
        {
          name: '맞춤제작',
          path: '/prime',
        },
        {
          name: '런칭',
          path: '',
          child: [
            {
              name: '부가서비스',
              path: '/additional_service',
            },
            {
              name: '케어서비스',
              path: '/care_service/intro',
            },
          ],
        },
        {
          name: '리소스',
          path: '',
          child: [
            {
              name: '공지사항',
              path: '/notice',
            },
            {
              name: 'FAQ',
              path: '/faq',
            },
            {
              name: '1:1문의',
              path: '/inquiry',
            },
            {
              name: '블로그',
              path: '/post',
            },
          ],
        },
        {
          name: '파트너 센터',
          path: '/partner_center',
        },
      ],
      myPageMenu: [
        { name: '내 서비스', path: '/mypage/my_service' },
        { name: '문의내역', path: '/mypage/inquiry' },
        { name: '미팅 관리', path: '/mypage/my_meeting' },
        { name: '계약내역', path: '/mypage/my_contract' },
        { name: '소스 파일 다운로드', path: '/mypage/my_templates' },
        { name: '북마크', path: '/mypage/my_bookmark' },
        { name: '내 정보 수정', path: '/mypage/profile' },
        { name: '로그아웃', path: 'logout' },
      ],
      partnerCenterMenu: [
        { name: '내 템플릿', path: '/partner_center/my_template' },
        { name: '고객 문의', path: '/partner_center/customer_inquiry' },
        { name: '미팅 관리', path: '/partner_center/meetings' },
        { name: '계약 내역', path: '/partner_center/contracts' },
        { name: '파트너 정보', path: '/partner_center/partner_info' },
        { name: '파트너 개발자 센터', path: '/partner_center/developer_center' },
      ],
      popupMarket: undefined,
    };
  },
  methods: {
    moveToPartnerCenter(route) {
      this.$router.push(route);
      this.$refs.pageDrawerLeft.close();
    },
    hideNotifications() {
      // Notifications 컴포넌트의 참조를 얻어 hide 메서드 호출
      if (this.$refs.notifications) {
        this.$refs.notifications.hide();
      }
    },
    clickPartnerMenu(menu) {
      this.$router.push(menu.path);
    },
    onMessageMarket(e) {
      if (e.data.close) {
        this.popupMarket.close();
        this.removeMessageEvent();
        if (e.data.redirectUrl) {
          setTimeout(() => {
            window.open(e.data.redirectUrl);
          }, 300);
        }
      }
    },
    addMessageEvent() {
      window.addEventListener('message', this.onMessageMarket);
    },
    removeMessageEvent() {
      window.removeEventListener('message', this.onMessageMarket);
    },
    clickInquiry() {
      this.$store.commit('setTrial', false);
      this.closeDrawerLeft();
      this.$router.push('/inquiry_reg?type_id=20');
    },
    clickLogo() {
      this.$store.commit('setTrial', false);
      if (this.$route.path === '/home') {
        this.$router.go(this.$route.currentRoute);
      } else {
        this.routerPush('/home');
      }
    },
    clickTop() {
      document.getElementsByTagName('html')[0].scrollTop = 0;
    },
    isCurrentMenu(menu) {
      return this.$route.path.indexOf(menu.path) > -1;
    },
    /* selectedMenuStyle(menu) {
      let selected = false;
      if (menu.path === this.$route.path) {
        selected = true;
      } else if (menu.child) {
        menu.child.forEach((c) => {
          let index = c.child.findIndex((item) => {
            return item.path === this.$route.path;
          });
          if (index > -1) {
            selected = true;
          }
        });
      }
      let deco = {
        color: '#242428',
        borderBottom: '3px solid transparent',
      };
      if (selected) {
        deco.color = '#ff6600';
        deco.borderBottom = '3px solid #ff6600';
      }
      return deco;
    }, */
    selectedChildStyle(item) {
      return item.path === this.$route.path ? { color: '#FF6600' } : '';
    },
    cellStyle(text) {
      let val = 0;
      if (text.length < 3) val = 62;
      else if (text.length < 5) val = 80;
      else if (text.length < 7) val = 110;
      else if (text.length < 9) val = 120;
      else if (text.length < 11) val = 140;
      else if (text.length < 13) val = 160;
      return { width: `${val}px` };
    },
    clickFirstMenu(menu) {
      if (!menu.child) {
        this.clickMenu(menu);
      } else if (this.selectedMenu && this.selectedMenu.path === menu.path) {
        this.selectedMenu = undefined;
      } else {
        this.selectedMenu = menu;
      }
    },
    // 메뉴 클릭
    clickMenu(menu) {
      this.$store.commit('setTrial', false);
      if (menu.path === 'logout') {
        this.clickLogout();
      } else if (menu.path === '/market/list') {
        if (this.isMobile) {
          this.routerPush('/appmarket_info');
        } else {
          /*const a = document.createElement('a');
            a.href = '/market/list';
            a.target = '_blank';
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            a.parentNode.removeChild(a);*/
          let url = '/market/list';
          let width = 1231;
          let height = 760;
          /*if(window.innerHeight < height) {
              height = window.innerHeight - 80;
            }
            if(window.innerWidth < width) {
              width = window.innerWidth - 200;
            }*/
          this.popupMarket = window.open(
            url,
            'appMarket',
            `width=${width}, height=${height}, top=0, left=0, menubar=no, toolbar=no, titlebar=yes`
          );
          this.addMessageEvent();
        }
      } else if (menu.path === '/quickbuilder' && this.isMobile) {
        this.toast('PC에서만 제공되는 서비스입니다');
        return;
      } else if (menu.path === '/inquiry' && !this.isLogin) {
        this.routerPush('/inquiry_reg');
      }
      // else if(menu.path === 'https://pfa.platformfy.com') {
      else if (menu.path.indexOf('https') > -1) {
        const a = document.createElement('a');
        a.href = menu.path;
        a.target = '_blank';
        document.body.appendChild(a);
        a.click();
        a.parentNode.removeChild(a);
      } else if (menu.path && this.$route.fullPath !== menu.path) {
        this.routerPush(menu.path);
      }
      this.closeDrawerLeft();

      /*  if (menu.path === 'req') {
            this.setGa('상단메뉴 (주문제작 신청)', '클릭', '주문제작 신청', 1);
            this.$refs.reqModal.open();
          } else {
            this.setGa(`상단 메뉴 (${menu.name})`, '클릭', menu.path, 1);
            this.$router.push('/' + menu.path);
          }*/
    },
    clickMyMenu(menu) {
      this.$store.commit('setTrial', false);
      if (menu.path === 'logout') {
        this.clickLogout();
      } else {
        this.$router.push(menu.path);
        this.closeDrawerLeft();
      }
    },
    onCloseDrawerLeft() {
      console.log('onCloseDrawerLeft');
      this.page_drawer_left = undefined;
      //this.$store.commit('removePopstate');
    },
    closeDrawerLeft() {
      if (window.innerWidth <= 1024) {
        this.$refs.pageDrawerLeft.close();
      }
    },
    clickLogin() {
      this.$store.commit('setPrevPath', this.$route.fullPath);
      this.$store.commit('setTrial', false);
      this.routerPush('/signin');
      this.closeDrawerLeft();
    },
    clickSignup() {
      this.$store.commit('setPrevPath', this.$route.fullPath);
      this.$store.commit('setTrial', false);
      this.routerPush('/signup');
      this.closeDrawerLeft();
    },
    // 로그아웃
    clickLogout() {
      this.logout();
      this.toast('로그아웃 되었습니다.');
      this.$router.replace('/home');
      this.closeDrawerLeft();

      /* this.$axios.post('/auth/user/logout?token=' + this.$store.getters.user.token)
          .then(res => {
            if (res.status === 200) {
              this.toast('로그아웃 되었습니다.');
              this.logout();
              this.$router.replace('/home');
              this.closeDrawerLeft();
            }
          }); */
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~assets/css/lp_main'
$pc-header-height = 64px

.beta
  position absolute
  right -26px
  top -3px
  color #ff6600
  font-size 10px


.lp-mypage-menu-bg
  display none

.user-region
  display flex
  align-items center
  justify-content center

.user-region:hover .lp-mypage-menu-bg
  position fixed
  z-index 100
  display block
  top 44px
  padding-top 12px
  transform translateX(-25%)

.lp-mypage-menu
  background white
  box-shadow 0px 0px 30px 0px rgba(0, 0, 0, 0.12)
  border-radius 8px
  width 164px

.mypage-item
  border-bottom 1px solid $gray2
.mypage-item:last-child
  border-bottom 0

.lp-mypage:hover .lp-mypage-menu-bg
  display block
.mypage-item:hover
  color $primary

.arrow_down
  width 18px
  height 18px
  margin-left 4px

.account_outline
  width 24px
  height 24px
  margin-right 8px

.lp-loading
  position fixed
  top 0
  left 0
  right 0
  bottom 0
  z-index 10000
  background rgba(0, 0, 0, 0.3)
  display flex
  justify-content center

.lp-loading i
  position fixed
  top 50%

.tri
  width 0
  height 0
  border-bottom 8px solid white
  border-right 8px solid transparent
  border-left 8px solid transparent
  transform translateX(-50%)
  /*-ms-border-bottom: 8px solid white;*/
  /*-ms-border-right: 8px solid transparent;*/
  /*-ms-border-left:8px solid transparent;*/
  -ms-transform translateX(-50%)

.btn-top
  font-size 12px
  position fixed
  bottom 160px
  right 46px
  z-index 1000
  background-color white
  box-shadow 3px 3px 6px 0 rgba(0, 0, 0, 0.11)
  width 50px
  height 50px
  border-radius 50%
  line-height 0
  text-align center

.drawer-container
  display flex
  flex-direction column
  width 100%
  height 100vh
  background white

.drawer-header
  border-bottom 1px solid $gray2
.drawer-content
  flex 1
  overflow-y auto

.alarm-btn
  position relative
  width 24px
  height 24px
  .alarm-btn-dot
    position absolute
    top 0
    right 2px
    width 9px
    height 9px
    background-color #FF3B3B
    border-radius 50%
    border 2px solid #FFFFFF
.partner_center_btn
.partner_center_btn-mobile
  display flex
  align-items center
  justify-content center
  padding 11px 24px
  border-radius 8px
  background linear-gradient(90deg, #563CDD 0%, #242428 34.2%)
  color #FFFFFF

.partner_center_btn
  position relative

.partner_center_btn:hover .partner_center_menu-wrapper
  display block

.partner_center_menu-wrapper
  display none
  padding-top 8px
  position absolute
  top 36px
  left 0
.partner_center_menu
  width 160px
  padding 12px 0
  background white
  box-shadow 0px 0px 30px 0px rgba(0, 0, 0, 0.12)
  border-radius 8px
  z-index 1000
  .partner_center_menu_item
    padding 0 16px 10px
    margin-bottom 10px
    border-bottom 1px solid #EEEEEE
    color #242428
  .partner_center_menu_item:last-child
    border-bottom 0
    margin-bottom 0
    padding-bottom 0
  .partner_center_menu_item:hover
    color #FF6600

.header-pc
  .header-container
    background-color #ffffff
    align-items center
    height $pc-header-height
    position fixed
    top 0
    left 0
    width 100%
    z-index 999
    border-bottom 1px solid $gray1

  .selected-menu-wrapper
    background-color white
    position fixed
    top ($pc-header-height - 10px)
    text-align left
    box-shadow 3px 0px 20px 0px rgba(0, 0, 0, 0.07)
    border-radius 8px

  .menu-item-name-1
    padding-top 16px
    color $sub4

  .menu-item-2
    color $main
    padding 12px 16px
    border-bottom 1px solid $gray2
  .menu-item-2:last-child
    border-bottom 0

  .menu-item-2:hover
    color $primary

  .menu-item-1
    padding-bottom 16px
    border-bottom 1px solid $gray2

  .menu-item-1:last-child
    border-bottom 0

  .selected-menu-wrapper
    display none
  .arrow-up
    display none
  .arrow-down
    display block

  .selected-menu:hover
    .selected-menu-wrapper
      display block
    .arrow-up
      display block
    .arrow-down
      display none

  .table-cell
    display flex
    align-items center
  .menu-name
    color $sub
    padding 14px
    display flex
    align-items center
    gap 5px
  .menu-name:hover
    color $primary !important
    position relative

.header-mobile
  .header-container
    background-color #ffffff
    align-items center
    height 60px
    position fixed
    top 0
    left 0
    width 100%
    z-index 10
    justify-content center
    border-bottom 1px solid $gray1

  .selected-menu-wrapper
    padding 24px
    background-color $gray4

.login-wrapper
  display flex
  align-items center
  justify-content center
  width 100%
  padding 16px;
  border-bottom 1px solid $gray2

.account-outline-wrapper
  position relative

.account-outline-wrapper img
  position absolute
  left 50%
  top 50%
  transform translate(-50%, -50%)
  font-size 13px

.account-outline-circle
  border-radius 50%
  padding 12px
  background-color $gray2

.fade-enter-active {
  transition: opacity .3s;
}
.fade-leave-active {
  transition: opacity .2s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.tag-trial
  font-size 12px
  font-weight 500
  border-radius 2px
  padding 0 6px
  background-color #EFECFF
  color #7B61FF

.divider-vertical
  width 1px
  height 14px
  background-color $gray
  margin-right 16px

@media (max-width:1024px)
  .btn-top
    right 14px
    bottom 130px
  .beta
    top 16px
    right -14px

  .btn-trial-container
    display flex
    flex-direction column
    justify-content center
    gap 16px
    padding 16px
    border-bottom 1px solid $gray2
  .btn-trial-wrapper
    display grid
    grid-template-columns 1fr 1fr
    align-items center
    gap 8px
    width 100%
</style>
