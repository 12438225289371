<template>
  <div style="position: relative;" v-click-outside="hide">
    <div class="position-relative alarm-btn unselect">
      <img src="/static/icon/ic_alarm.svg" style="width: 24px; height: 24px;" @click="clickShowNotifi" />
      <div v-if="nonReadNotiCount > 0" class="alarm-btn-dot"></div>
    </div>
    <div v-if="showNotifi" class="noti-wrap">
      <div class="noti">
        <div class="noti-title">
          <div class="flex-align" style="gap: 4px;">
            <div class="h8 main">알림</div>
            <div v-if="nonReadNotiCount > 0" class="noti-count">{{ nonReadNotiCount }}</div>
          </div>
          <i class="material-icons unselect close-btn" @click="clickShowNotifi">close</i>
        </div>
        <div class="card-wrap" v-if="notifications && notifications.length !== 0">
          <div v-for="(item, idx) in notifications" :key="`notification-list-${idx}`" @click="clickShowNotifi">
            <card-notification :card="item" @checkUpdate="checkChange"></card-notification>
          </div>
        </div>
        <div v-else class="non-notifi p-sub2">
          <img src="/static/icon/fi_notifications_w.svg" />
          <span>새로운 알림이 없습니다.</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardNotification from './CardNotification';
const io = require('socket.io-client');
export default {
  name: 'Notifications',
  components: {
    CardNotification,
  },
  data() {
    return {
      showNotifi: false,
      newNotification: undefined,
      nonReadNotiCount: 0,
      notifications: [
        {
          bid_id: null,
          created_time: '2024-04-26 16:03',
          group: null,
          id: 1058,
          img: '',
          is_read: false,
          link: '/order_detail_estimate?id=350',
          model_id: 350,
          model_name: 'order',
          msg: '탐앤탐스 님이 주문을 취소했습니다.',
          thumbnail:
            'https://launchpack-storage.s3.ap-northeast-2.amazonaws.com/media/lpthemeestimate/non-path/40264242.png',
          title: '',
          user: 87,
        },
      ],
    };
  },
  methods: {
    setSocketIO() {
      this.socket = io.connect('https://master-dev.launchpack.co.kr:3000');
      setTimeout(() => {
        this.socket.on('message', (msg) => {
          if (msg.type === 'notification') {
            this.getNotification();
          }
        });
      }, 500);
    },
    hide() {
      this.showNotifi = false;
    },
    clickShowNotifi() {
      this.showNotifi = !this.showNotifi;
    },
    checkChange(e) {
      this.showNotifi = e;
    },
    getNotification() {
      this.$axios.get(`user/${this.user.user_id}/notification`).then((res) => {
        this.notifications = res.data;
        this.readNotification(res.data);
      });

      this.$axios.get(`user/${this.user.user_id}/notification?is_read=false`).then((res) => {
        this.newNotification = res.data.length;
      });
    },
    readNotification(data) {
      this.nonReadNotiCount = data.filter((item) => item.is_read === false).length;
    },
  },
  created() {
    this.setSocketIO();
    this.getNotification();
  },
};
</script>

<style lang="stylus" scoped>
.noti-wrap
  position absolute
  top 40px
  right -178px
  z-index 1010

.noti-title
  display flex
  align-items center
  gap 4px
  padding 12px 16px
  border-bottom 1px solid #E3E3E3

.noti-count
  display flex
  align-items center
  justify-content center
  width auto
  height 16px
  padding 2px 5px
  background-color #FF3B3B
  color #FFFFFF
  border-radius 50%
  font-size 10px
  font-weight 600
.noti
  position relative
  width 400px
  background-color white
  border 1px solid #E3E3E3
  border-radius 10px
  &:after
    content ''
    position absolute
    border-style solid
    border-width 0 10px 10px 10px
    border-color white transparent
    display block
    width 0
    z-index 1
    top -8px
    right 178px
  &:before
    content ''
    position absolute
    border-style solid
    border-width 0 10px 10px 10px
    border-color #E3E3E3 transparent
    display block
    width 0
    z-index 0
    top -10px
    right 178px
  .close-btn
    display none
  .default-notifi
    text-align center
    line-height 2.5
    width 100%
    height 36px
  .non-notifi
    display flex
    flex-direction column
    align-items center
    justify-content center
    height 30vh
    gap 10px
  .card-wrap
    overflow-y auto
    max-height 70vh
    min-height 30vh
  .new-alert
    border-radius 50%
    width 6px
    height 6px
    position absolute
    top 0
    right 0

.alarm-btn
  position relative
  width 24px
  height 24px
  .alarm-btn-dot
    position absolute
    top 0
    right 2px
    width 9px
    height 9px
    background-color #FF3B3B
    border-radius 50%
    border 2px solid #FFFFFF

@media (max-width: 768px)
  .noti-wrap
    position fixed
    width 100%
    top 60px
    left 0
    right 0
    .noti
      border-radius 0
      border 0
      width 100%
      height calc(100vh - 60px)
      &:before
      &:after
        display none
      .noti-title
        justify-content space-between
      .close-btn
        display block
        color #818181
      .card-wrap
        max-height calc(100vh - 105px)

</style>
